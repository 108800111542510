<template>
  <div v-html="apply">
    {{apply}}
  </div>
</template>

<script>
export default {
  name: "apply",
  data() {
    return {
      apply: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .updateRegistrationStatus({
          id: this.$route.query.id,
          examMajorTitle: this.$route.query.examMajorTitle,
        })
        .then((data) => {
          console.log(data);
          this.apply = data.msg;
          this.$nextTick(() => {
            document.forms[0].submit();
          });
        })
        .catch((error) => {});
    },
  },
};
</script>